<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader :container="container"/>
      <div class="c-body">
        <main class="c-main p-0">
          <template v-if="container">
            <CContainer :fluid="$store.state.containerFluid">
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </CContainer>
          </template>
          <template v-else>
            <router-view></router-view>
          </template>
        </main>
        <TheFooter/>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import store from '../Store';

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data () {
    return {
      container: true
    }
  },
  beforeRouteEnter: function (to, from, next) {
    store.dispatch('fetchUser').then(function(){
      next((vm) => {})
    });
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
