<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            :src="user.picture"
            class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Profil</strong>
      <br>{{ user.name }}
    </CDropdownHeader>
    <CDropdownItem v-on:click="$router.push('/user/profile')">
      <i class="cil-user icons" />Profil
    </CDropdownItem>
    <CDropdownItem v-on:click="$router.push('/user/company')" v-if="permissionCheck('data')">
      <i class="cil-building icons" />Firmendaten
    </CDropdownItem>
    <CDropdownItem v-on:click="$router.push('/user/ci')" v-if="permissionCheck('data')">
      <i class="cil-image icons" />Firmenlogo
    </CDropdownItem>
    <CDropdownItem v-on:click="$router.push('/user/invoices')" v-if="permissionCheck('data')">
      <i class="cil-envelope-closed icons" />Rechnungen
    </CDropdownItem>
    <CDropdownItem v-on:click="$auth.logOut()">
      <i class="cil-account-logout icons" />Abmelden
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import authService from '../auth/authService';
import { permissionCheck } from '@/shared/permission.js';

export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      permissionCheck: permissionCheck,
      user: {
        name: ''
      },
      profile: {
        picture: ''
      }
    }
  },
  created: function () {
    this.user = this.$store.getters.getUser;
    if(authService.profile !== null){
      this.profile = authService.profile;
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
